import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './root-reducer';
import rootSaga from './root-saga';

const sagaMiddleware = createSagaMiddleware();

// /** Create middlewares for redux */
const middlewares = [sagaMiddleware];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);

  // // Enable redux devtool if browser extension is installed
  // if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  //   middlewares = compose(middlewares, window.__REDUX_DEVTOOLS_EXTENSION__());
  // }
}
// /** Create redux store */
export const store = createStore(rootReducer, applyMiddleware(...middlewares));

// Run Saga
sagaMiddleware.run(rootSaga);

// /** Create persistor */
export const persistor = persistStore(store);

export default { store, persistor };
