import axios from 'config/client';

export const getReminderEmailTemplates = async () => {
  try {
    const response = await axios.get('reminder-email-templates');
    return response;
  } catch (error) {
    if (error.message === 'Network Error') throw new Error('Something went wrong!');
    throw new Error(error.response.data.message);
  }
};

export const getReminderEmailTemplate = async payload => {
  try {
    const { uniCompanyName, eventType } = payload;
    const reminderEmailTemplate = await axios.post('reminder-email-templates', {
      unicityCompanyName: uniCompanyName,
      eventType: eventType
    });

    return reminderEmailTemplate;
  } catch (error) {
    if (error.message === 'Network Error') throw new Error('Something went wrong!');
    throw new Error(error.response.data.message);
  }
};
