import AuthTypes from './types';
import { REHYDRATE } from 'redux-persist';
import Cookie from 'js-cookie';

const INITIAL_STATE = {
  username: null,
  userProfile: null,
  timestamp: null,
  refreshToken: null,
  isFetching: false,
  error: null,
  accessDenied: false,
  cookieToken: Cookie.get('access_token'),
  isAuth: false
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.LOGIN:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case AuthTypes.TOKEN_LOGIN:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case AuthTypes.ACCESS_DENIED:
      return { ...INITIAL_STATE, cookieToken: null };
    case AuthTypes.SET_STATE:
      return { ...state, isFetching: false, ...action.payload };
    case REHYDRATE:
      if (action.payload && action.payload.auth) {
        return {
          ...state,
          ...action.payload.auth,
          cookieToken: Cookie.get('access_token'),
          isFetching: false,
          error: null
        };
      }
      return {
        ...state,
        isFetching: false,
        error: null
      };
    case AuthTypes.LOGOUT:
      localStorage.removeItem('access_token');
      return { ...INITIAL_STATE, cookieToken: null };
    default:
      return state;
  }
};

export default authReducer;
