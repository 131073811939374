import StatusErrorsActionTypes from './types';

const INITIAL_STATE = {
  statusErrorList: null,
  isFetching: false,
  errorMessage: undefined,
  isDeleting: false
};

const statusErrorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case StatusErrorsActionTypes.FETCH_STATUS_ERRORS_START:
      return {
        ...state,
        isFetching: true
      };
    case StatusErrorsActionTypes.FETCH_STATUS_ERRORS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        statusErrorList: action.payload,
        errorMessage: undefined
      };
    case StatusErrorsActionTypes.FETCH_STATUS_ERRORS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload
      };
    case StatusErrorsActionTypes.DELETE_STATUS_ERROR_START:
      return {
        ...state,
        isDeleting: true
      };
    case StatusErrorsActionTypes.DELETE_STATUS_ERROR_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        statusErrorList: action.payload,
        errorMessage: undefined
      };
    case StatusErrorsActionTypes.DELETE_STATUS_ERROR_FAILURE:
      return {
        ...state,
        isDeleting: false,
        errorMessage: action.payload
      };
    default:
      return state;
  }
};

export default statusErrorReducer;
