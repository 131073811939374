import axios from 'axios';
import { store } from 'store/index';
import { logout } from 'modules/auth/redux/actions';
import { isValidToken } from 'utils/auth';
import { openSnackbar } from 'modules/snackbar/redux/actions';

export const endpoint =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_API_URL_DEV
    : process.env.REACT_APP_API_URL_PROD;

const axiosInstance = axios.create({
  baseURL: endpoint
});

const ignoreAPIs = ['/login', '/token-login'];

axiosInstance.interceptors.request.use(
  async function(config) {
    const token = localStorage.getItem('access_token');

    if (!ignoreAPIs.some(s => config.url.includes(s))) {
      const isTokenValid = await isValidToken(token);
      if (!isTokenValid) {
        throw new axios.Cancel('Unauthorized operation!');
      }
    }

    const { headers } = config;
    config.headers = {
      ...headers,
      Authorization: `Bearer ${token}`
    };
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  async function(error) {
    // const originalRequest = error.config;
    const { isAuth } = store.getState().auth;
    if (error?.response?.status === 401 || error?.message === 'Unauthorized operation!') {
      if (isAuth) {
        store.dispatch(
          openSnackbar({
            type: 'error',
            message: error?.response?.data?.message || 'invalid token!!'
          })
        );
        return store.dispatch(logout());
      }
      return;
      /* call refresh token
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
      return axiosApiInstance(originalRequest);
      */
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
