import POLinesActionTypes from './types';

const INITIAL_STATE = {
  poDetailId: null,
  poDetailList: null,
  isFetching: false,
  isLoadingShipmentList: false,
  emailThread: null,
  isFetchingEmail: false,
  errorMessage: undefined,
  isEmailFetching: false,
  email: [],
  emailError: null,
  shipmentDetails: [],
  activities: [],
  activitiesLoading: false,
  activitiesError: null,
  activityDeleteID: null,
  documents: [],
  importantMessages: [],
  documentsError: null,
  forwarderList: [],
  detailsMultiLot: [],
  documentsShipment: [],
  documentDeleteID: null,
  newDocument: null,
  isUploading: false,
  isDeleting: false,
  isLoadingShipment: false,
  isShipmentCircularProgress: false,
  newShipmentDetails: [],
  newDocumentsShipment: [],
  poLots: [],
  createLotSuccess: false,
  newPOLots: [],
  isSavingPoLot: false,
  shipmentList: null,
  shipmentMultiPo: null,
  statusCircle: null,
  previousPath: undefined
};

const purchaseDetailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POLinesActionTypes.FETCH_PO_DETAILED_START:
      return {
        ...state,
        isFetching: true,
        shipmentDetails: [],
        poDetailId: action,
        activities: [],
        activitiesLoading: false,
        activitiesError: null,
        errorMessage: undefined
      };
    case POLinesActionTypes.FETCH_PO_DETAILED_LINES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        poDetailList: action.payload
      };
    case POLinesActionTypes.FETCH_PO_DETAILED_LINES_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload
      };

    case POLinesActionTypes.FETCH_PO_EMAIL_THREAD_START:
      return {
        ...state,
        emailThread: [],
        poDetailId: action,
        isFetchingEmail: true
      };
    case POLinesActionTypes.FETCH_PO_EMAIL_THREAD_SUCCESS:
      return {
        ...state,
        isFetchingEmail: false,
        emailThread: action.payload
      };
    case POLinesActionTypes.FETCH_PO_EMAIL_THREAD_FAILURE:
      return {
        ...state,
        isFetchingEmail: false,
        errorMessage: action.payload
      };
    case POLinesActionTypes.FETCH_PO_EMAIL_BY_ID_INIT:
      return {
        ...state,
        isEmailFetching: true
      };
    case POLinesActionTypes.FETCH_PO_EMAIL_BY_ID_SUCCESS:
      return {
        ...state,
        isEmailFetching: false,
        email: action.payload
      };
    case POLinesActionTypes.FETCH_PO_EMAIL_BY_ID_FAILURE:
      return {
        ...state,
        isEmailFetching: false,
        emailError: action.payload
      };
    case POLinesActionTypes.FETCH_PO_SHIPMENT_SUCCESS:
      return {
        ...state,
        shipmentDetails: action.payload
      };
    case POLinesActionTypes.FETCH_IMPORTANT_MESSAGES_EMAIL_THREAD_INIT:
      return {
        ...state
      };
    case POLinesActionTypes.FETCH_IMPORTANT_MESSAGES_EMAIL_THREAD_SUCCESS:
      return {
        ...state,
        importantMessages: action.payload
      };
    case POLinesActionTypes.FETCH_IMPORTANT_MESSAGES_EMAIL_THREAD_FAILURE:
      return {
        ...state,
        errorMessage: action.payload
      };
    case POLinesActionTypes.SET_STATE:
      //eslint-disable-next-line
      const { payload } = action;
      return {
        ...state,
        ...payload
      };
    case POLinesActionTypes.FETCH_FORWARDER_INIT:
      return {
        ...state,
        forwarderList: []
      };
    case POLinesActionTypes.FETCH_FORWARDER_SUCCESS:
      return {
        ...state,
        forwarderList: action.payload
      };
    case POLinesActionTypes.FETCH_SHIPMENT_DETAILS_MULTI_LOT_INIT:
      return {
        ...state,
        detailsMultiLot: []
      };
    case POLinesActionTypes.FETCH_SHIPMENT_DETAILS_MULTI_LOT_SUCCESS:
      return {
        ...state,
        detailsMultiLot: action.payload
      };
    case POLinesActionTypes.FETCH_SHIPMENT_DETAILS_MULTI_LOT_FAILURE:
      return {
        ...state,
        errorMessage: action.payload
      };
    case POLinesActionTypes.FETCH_DOCUMENTS_SHIPMENT_INIT:
      return {
        ...state,
        isLoadingShipmentList: true,
        documentsShipment: []
      };
    case POLinesActionTypes.FETCH_DOCUMENTS_SHIPMENT_SUCCESS:
      return {
        ...state,
        isLoadingShipmentList: false,
        documentsShipment: action.payload
      };
    case POLinesActionTypes.FETCH_DOCUMENTS_SHIPMENT_FAILURE:
      return {
        ...state,
        isLoadingShipmentList: false,
        errorMessage: action.payload
      };
    case POLinesActionTypes.ADD_SHIPMENT_DETAILS_INIT:
      return {
        ...state,
        dataChangeShipment: [],
        data: action,
        isLoadingShipment: false
      };
    case POLinesActionTypes.ADD_SHIPMENT_DETAILS_SUCCESS:
      return {
        ...state,
        isLoadingShipment: true,
        dataChangeShipment: action.payload
      };
    case POLinesActionTypes.ADD_SHIPMENT_DETAILS_FAILURE:
      return {
        ...state,
        isLoadingShipment: false,
        errorMessage: action.payload
      };
    case POLinesActionTypes.DELETE_SHIPMENT_DETAILS_INIT:
      return {
        ...state,
        deleteShipment: [],
        data: action,
        isLoadingShipment: false
      };
    case POLinesActionTypes.DELETE_SHIPMENT_DETAILS_SUCCESS:
      return {
        ...state,
        isLoadingShipment: true,
        deleteShipment: action.payload
      };
    case POLinesActionTypes.DELETE_SHIPMENT_DETAILS_FAILURE:
      return {
        ...state,
        isLoadingShipment: false
      };
    case POLinesActionTypes.FETCH_PO_LOT:
      return {
        ...state,
        poLots: []
      };
    case POLinesActionTypes.FETCH_PO_LOT_SUCCESS:
      return {
        ...state,
        poLots: action.payload
      };
    case POLinesActionTypes.FETCH_PO_LOT_FAILURE:
      return {
        ...state,
        errorMessage: action.payload
      };
    case POLinesActionTypes.FETCH_SHIPMENT_LIST_START:
      return {
        ...state,
        isFetching: true
      };
    case POLinesActionTypes.FETCH_SHIPMENT_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        shipmentList: action.payload
      };
    case POLinesActionTypes.FETCH_SHIPMENT_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload
      };
    case POLinesActionTypes.FETCH_SHIPMENT_LIST_MULTI_PO_START:
      return {
        ...state
      };
    case POLinesActionTypes.FETCH_SHIPMENT_LIST_MULTI_PO_SUCCESS:
      return {
        ...state,
        shipmentMultiPo: action.payload
      };
    case POLinesActionTypes.FETCH_SHIPMENT_LIST_MULTI_PO_FAILURE:
      return {
        ...state,
        errorMessage: action.payload
      };
    default:
      return state;
  }
};

export default purchaseDetailReducer;
