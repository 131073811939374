import axios from 'config/client';

// GET Batchlot
export const getBatchLot = async () => {
  try {
    return await axios.get('batchlot-list');
  } catch (error) {
    console.error(`Error: ${error.message}`);
    if (error.message === 'Network Error') throw new Error('Something went wrong!');
    throw new Error(error.response.data.message);
  }
};
