import jwt from 'jsonwebtoken';

export const isValidToken = async token => {
  try {
    let isValid = true;

    jwt.verify(token, process.env.REACT_APP_JWT_SECRET_KEY, function(err, decoded) {
      // err
      if (err) console.log('err', err);

      if (!decoded) isValid = false;
    });

    return isValid;
  } catch (error) {
    throw new Error(error);
  }
};

export const handleError = error => {
  if (error.response.status === 401) {
    window.location.href = '/login';
  }

  if (error.response.status === 403) {
    throw new Error(403);
  }

  //eslint-disable-next-line
  if (error.response) {
    const message = error.response && error.response.data && error.response.data.message;
    if (message) {
      throw new Error(error.response ? error.response.data.message : error.message);
    }
  } else if (error.request) {
    //eslint-disable-next-line
    console.log('error.request', 'Network error!');
  } else {
    //eslint-disable-next-line
    console.log('An unknown error has occurred!');
  }
};
