import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import loadable from '@loadable/component';
import routes from './routes';
import Analytics from 'react-router-ga';

// Components and Pages
const WithHeaderLayout = loadable(() => import('components/Layout/WithHeader'));
const AuthGaurd = loadable(() => import('modules/auth/screens/auth-guard'));

const RouteLayout = ({ layout: Layout, component, path, exact }) => {
  if (Layout) {
    return (
      <Layout>
        <Route exact={exact} path={path} component={component} />
      </Layout>
    );
  }
  return (
    <WithHeaderLayout>
      <Route exact={exact} path={path} component={component} />
    </WithHeaderLayout>
  );
};

const Router = () => {
  const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
  return (
    <Analytics id={trackingId}>
      <AuthGaurd>
        <Helmet>
          <title>Order Tracking System | Foresent</title>
        </Helmet>

        <Switch>
          <Route exact path="/">
            <Redirect to={'/purchase-order'} />
          </Route>

          {routes.map((route, index) => {
            return route.noLayout ? (
              <Route
                key={`route$-${index}`}
                path={route.path}
                component={route.Component}
                exact={route.exact}
              />
            ) : (
              <RouteLayout
                key={`layout-route$-${index}`}
                path={route.path}
                component={route.Component}
                exact={route.exact}
              />
            );
          })}
        </Switch>
      </AuthGaurd>
    </Analytics>
  );
};

export default Router;
