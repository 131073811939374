import UserInfoActionTypes from './types';

export const getUserInfoAction = payload => ({
  type: UserInfoActionTypes.GET_USER_INFO,
  payload
});

export const getUserInfoSuccessAction = payload => {
  let { result } = payload;
  return {
    type: UserInfoActionTypes.GET_USER_INFO_SUCCESS,
    payload: result
  };
};

export const createUserInfoAction = payload => ({
  type: UserInfoActionTypes.CREATE_USER_INFO,
  payload
});

export const createUserInfoSuccessAction = payload => {
  let { result } = payload;
  return {
    type: UserInfoActionTypes.CREATE_USER_INFO_SUCCESS,
    payload: result
  };
};

export const updateUserInfoAction = payload => ({
  type: UserInfoActionTypes.UPDATE_USER_INFO,
  payload
});

export const updateUserInfoSucessAction = payload => ({
  type: UserInfoActionTypes.UPDATE_USER_INFO_SUCCESS,
  payload
});

export const fetchUserInfoFailureAction = payload => ({
  type: UserInfoActionTypes.FETCH_USER_INFO_FAILURE,
  payload
});
