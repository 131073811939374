const StatusErrorsActionTypes = {
  FETCH_STATUS_ERRORS_START: 'FETCH_STATUS_BATCHLOT_START',
  FETCH_STATUS_ERRORS_SUCCESS: 'FETCH_STATUS_BATCHLOT_SUCCESS',
  FETCH_STATUS_ERRORS_FAILURE: 'FETCH_STATUS_BATCHLOT_FAILURE',
  DELETE_STATUS_ERROR_START: 'DELETE_STATUS_BATCHLOT_START',
  DELETE_STATUS_ERROR_SUCCESS: 'DELETE_STATUS_BATCHLOT_SUCCESS',
  DELETE_STATUS_ERROR_FAILURE: 'DELETE_STATUS_BATCHLOT_FAILURE'
};

export default StatusErrorsActionTypes;
