const StatusErrorsActionTypes = {
  FETCH_STATUS_ERRORS_START: 'FETCH_STATUS_SHIPMENTUNDERQ_START',
  FETCH_STATUS_ERRORS_SUCCESS: 'FETCH_STATUS_SHIPMENTUNDERQ_SUCCESS',
  FETCH_STATUS_ERRORS_FAILURE: 'FETCH_STATUS_SHIPMENTUNDERQ_FAILURE',
  DELETE_STATUS_ERROR_START: 'DELETE_STATUS_SHIPMENTUNDERQ_START',
  DELETE_STATUS_ERROR_SUCCESS: 'DELETE_STATUS_SHIPMENTUNDERQ_SUCCESS',
  DELETE_STATUS_ERROR_FAILURE: 'DELETE_STATUS_SHIPMENTUNDERQ_FAILURE'
};

export default StatusErrorsActionTypes;
