import get from 'lodash/get';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getDocumentShipmentInit,
  getEmailById,
  getForwardList,
  getImportantMessagesEmailThread,
  getPOEmailThread,
  getPOLots,
  getPurchaseByPO,
  getShipmentDetails,
  getShipmentDetailsMultiLotInit,
  getAllShipmentMultiPo,
  getAllShipment
} from '../services';
import {
  deleteDocument,
  deleteDocumentFailure,
  fetchImportantMessagesEmailSuccess,
  fetchImportantMessagesEmailSuccessFailure,
  fetchPoDetailFailure,
  fetchPoDetailSuccess,
  fetchPoEmailFailure,
  fetchPoEmailSuccess,
  getDocumentsShipmentFailure,
  getDocumentsShipmentSuccess,
  getEmailByIdFailure,
  getEmailByIdSuccess,
  getForwardFailure,
  getForwardSuccess,
  getPOLotsFailure,
  getPOLotsSuccess,
  getShipmentDetailsMultiLotFailure,
  getShipmentDetailsMultiLotSuccess,
  setShipmentDetails,
  fetchShipmentListSuccess,
  fetchShipmentListFailure,
  fetchShipmentListMultiPoSuccess,
  fetchShipmentListMultiPoFailure
} from './actions';
import setStateSaga from './sagas.activities';
import POLinesActionTypes from './types';

function* getPurchaseOrderDetailedList(action) {
  try {
    const purchaseOrderDetail = yield call(getPurchaseByPO, action.payload);
    const shipmentDetails = yield call(getShipmentDetails, action.payload);
    yield put(setShipmentDetails(get(shipmentDetails, 'data', [])));
    yield put(fetchPoDetailSuccess(get(purchaseOrderDetail, 'data', [])));
  } catch (error) {
    yield put(fetchPoDetailFailure(error.message));
  }
}

export function* fetchPurchaseDetailedStart() {
  yield takeLatest(POLinesActionTypes.FETCH_PO_DETAILED_START, getPurchaseOrderDetailedList);
}

function* getPOEmailThreadList(action) {
  try {
    const emailThread = yield call(getPOEmailThread, action.payload);
    yield put(fetchPoEmailSuccess(get(emailThread, 'data.data', [])));
  } catch (error) {
    yield put(fetchPoEmailFailure(error.message));
  }
}

export function* fetchPOEmailStart() {
  yield takeLatest(POLinesActionTypes.FETCH_PO_EMAIL_THREAD_START, getPOEmailThreadList);
}

function* getEmailByIdSaga(action) {
  try {
    const emailThread = yield call(getEmailById, action.payload);
    yield put(getEmailByIdSuccess(get(emailThread, 'data.data', [])));
  } catch (error) {
    console.log('error>>', error.message);
    yield put(getEmailByIdFailure(error.message));
  }
}

export function* getEmailByIdStart() {
  yield takeLatest(POLinesActionTypes.FETCH_PO_EMAIL_BY_ID_INIT, getEmailByIdSaga);
}
function* getImportantMessagesEmailThreadList(action) {
  try {
    const importantMessages = yield call(getImportantMessagesEmailThread, action.payload);
    yield put(fetchImportantMessagesEmailSuccess(get(importantMessages, 'data.data', [])));
  } catch (error) {
    yield put(fetchImportantMessagesEmailSuccessFailure(error.message));
  }
}
export function* fetchImportantMessagesEmail() {
  yield takeLatest(
    POLinesActionTypes.FETCH_IMPORTANT_MESSAGES_EMAIL_THREAD_INIT,
    getImportantMessagesEmailThreadList
  );
}

export function* deleteDocuemnt(action) {
  try {
    yield call(deleteDocument, action.payload);
    yield put(POLinesActionTypes.DELETE_DOCUMENT_SUCCESS, deleteDocument);
  } catch (error) {
    yield put(deleteDocumentFailure(error));
  }
}

function* getForwardListData() {
  try {
    const forwarderList = yield call(getForwardList);
    yield put(getForwardSuccess(get(forwarderList, 'data', [])));
  } catch (error) {
    console.log('error>>', error.message);
    yield put(getForwardFailure(error.message));
  }
}

export function* getForwardListInit() {
  yield takeLatest(POLinesActionTypes.FETCH_FORWARDER_INIT, getForwardListData);
}

function* getShipmentDetailsLotsInitSaga(action) {
  try {
    const detailsMultiLot = yield call(getShipmentDetailsMultiLotInit, action.payload);
    yield put(getShipmentDetailsMultiLotSuccess(get(detailsMultiLot, 'data.data', [])));
  } catch (error) {
    console.log('error>>', error.message);
    yield put(getShipmentDetailsMultiLotFailure(error.message));
  }
}

function* getPOLotsSaga(action) {
  try {
    const response = yield call(getPOLots, action.payload);
    yield put(getPOLotsSuccess(get(response, 'data', [])));
  } catch (error) {
    console.log('error>>', error.message);
    yield put(getPOLotsFailure(error.message));
  }
}

export function* getShipmentDetailsLotsInit() {
  yield takeLatest(
    POLinesActionTypes.FETCH_SHIPMENT_DETAILS_MULTI_LOT_INIT,
    getShipmentDetailsLotsInitSaga
  );
}

function* getDataDocumentsShipmentInitSaga() {
  try {
    const documentsShipment = yield call(getDocumentShipmentInit);
    yield put(getDocumentsShipmentSuccess(documentsShipment));
  } catch (error) {
    console.log('error>>', error.message);
    yield put(getDocumentsShipmentFailure(error.message));
  }
}

export function* getDataDocumentsShipmentInit() {
  yield takeLatest(
    POLinesActionTypes.FETCH_DOCUMENTS_SHIPMENT_INIT,
    getDataDocumentsShipmentInitSaga
  );
}

// function* getDataAddShipmentInitSaga(action) {
//   try {
//     const dataChangeShipment = yield call(
//       createShipmentDetails,
//       action.payload.data
//     );

//     // const {
//     //   newShipmentDetails,
//     //   newDocumentsShipment
//     // } = dataChangeShipment.data;
//     // yield put(
//     //   setState({
//     //     shipmentDetails: [...shipmentDetails, newShipmentDetails],
//     //     documentsShipment: [...documentsShipment, newDocumentsShipment]
//     //   })
//     // );
//     yield put(
//       getDataChangeShipmentSuccess(get(dataChangeShipment, "data", []))
//     );

//     const purchaseOrderDetail = yield call(
//       getPurchaseByPO,
//       action.payload.data.puchaseOrderId
//     );
//     const shipmentDetails = yield call(
//       getShipmentDetails,
//       action.payload.data.puchaseOrderId
//     );
//     const documentsShipment = yield call(getDocumentShipmentInit);
//     yield put(
//       getDocumentsShipmentSuccess(get(documentsShipment, "data.data", []))
//     );
//     const detailsMultiLot = yield call(
//       getShipmentDetailsMultiLotInit,
//       action.payload.data.puchaseOrderId
//     );
//     yield put(
//       getShipmentDetailsMultiLotSuccess(get(detailsMultiLot, "data.data", []))
//     );
//     yield put(setShipmentDetails(get(shipmentDetails, "data", [])));
//     yield put(fetchPoDetailSuccess(get(purchaseOrderDetail, "data", [])));
//   } catch (error) {
//     console.log("error>>", error.message);
//     yield put(getDataChangeShipmentFailure(error.message));
//   }
// }

// export function* getDataAddShipmentInit() {
//   yield takeLatest(
//     POLinesActionTypes.ADD_SHIPMENT_DETAILS_INIT,
//     getDataAddShipmentInitSaga
//   );
// }

// function* getDataEditShipmentInitSaga(action) {
//   try {
//     const dataChangeShipment = yield call(
//       createShipmentDetails,
//       action.payload.data
//     );
//     const purchaseOrderDetail = yield call(
//       getPurchaseByPO,
//       action.payload.data.puchaseOrderId
//     );
//     const shipmentDetails = yield call(
//       getShipmentDetails,
//       action.payload.data.puchaseOrderId
//     );
//     const documentsShipment = yield call(getDocumentShipmentInit);
//     yield put(
//       getDocumentsShipmentSuccess(get(documentsShipment, "data.data", []))
//     );
//     const detailsMultiLot = yield call(
//       getShipmentDetailsMultiLotInit,
//       action.payload.data.puchaseOrderId
//     );
//     yield put(
//       getShipmentDetailsMultiLotSuccess(get(detailsMultiLot, "data.data", []))
//     );
//     yield put(setShipmentDetails(get(shipmentDetails, "data", [])));
//     yield put(fetchPoDetailSuccess(get(purchaseOrderDetail, "data", [])));
//     yield put(
//       getDataChangeShipmentSuccess(get(dataChangeShipment, "data.data", []))
//     );
//   } catch (error) {
//     console.log("error>>", error.message);
//     yield put(getDataChangeShipmentFailure(error.message));
//   }
// }

// export function* getDataEditShipmentInit() {
//   yield takeLatest(
//     POLinesActionTypes.EDIT_SHIPMENT_DETAILS_INIT,
//     getDataEditShipmentInitSaga
//   );
// }

// function* getDeleteShipmentInitSaga(action) {
//   try {
//     const deleteShipment = yield call(
//       geDeleteShipmentInit,
//       action.payload.data
//     );
//     const purchaseOrderDetail = yield call(
//       getPurchaseByPO,
//       action.payload.data.puchaseOrderId
//     );
//     const shipmentDetails = yield call(
//       getShipmentDetails,
//       action.payload.data.puchaseOrderId
//     );
//     const documentsShipment = yield call(getDocumentShipmentInit);
//     yield put(
//       getDocumentsShipmentSuccess(get(documentsShipment, "data.data", []))
//     );
//     const detailsMultiLot = yield call(
//       getShipmentDetailsMultiLotInit,
//       action.payload.data.puchaseOrderId
//     );
//     yield put(
//       getShipmentDetailsMultiLotSuccess(get(detailsMultiLot, "data.data", []))
//     );
//     yield put(setShipmentDetails(get(shipmentDetails, "data", [])));
//     yield put(fetchPoDetailSuccess(get(purchaseOrderDetail, "data", [])));
//     yield put(getDeleteShipmentSuccess(get(deleteShipment, "data.data", [])));
//   } catch (error) {
//     console.log("error>>", error.message);
//     yield put(getDeleteShipmentFailure(error.message));
//   }
// }

// export function* getDeleteShipmentInit() {
//   yield takeLatest(
//     POLinesActionTypes.DELETE_SHIPMENT_DETAILS_INIT,
//     getDeleteShipmentInitSaga
//   );
// }

export function* getPOLotsInit() {
  yield takeLatest(POLinesActionTypes.FETCH_PO_LOT, getPOLotsSaga);
}

function* getShipmentList() {
  try {
    const shipmentList = yield call(getAllShipment);
    yield put(fetchShipmentListSuccess(get(shipmentList, 'data', [])));
  } catch (error) {
    yield put(fetchShipmentListFailure, error);
  }
}

export function* fetchShipmentStart() {
  yield takeLatest(POLinesActionTypes.FETCH_SHIPMENT_LIST_START, getShipmentList);
}

function* getShipmentListMultiPo() {
  try {
    const shipmentListMultiPo = yield call(getAllShipmentMultiPo);
    yield put(fetchShipmentListMultiPoSuccess(get(shipmentListMultiPo, 'data.data', [])));
  } catch (error) {
    yield put(fetchShipmentListMultiPoFailure, error);
  }
}

export function* fetchShipmentMultiPoStart() {
  yield takeLatest(POLinesActionTypes.FETCH_SHIPMENT_LIST_MULTI_PO_START, getShipmentListMultiPo);
}

export function* purchaseLineSagas() {
  yield all([
    call(fetchPurchaseDetailedStart),
    call(fetchPOEmailStart),
    call(getEmailByIdStart),
    call(setStateSaga),
    call(fetchImportantMessagesEmail),
    call(getForwardListInit),
    call(getShipmentDetailsLotsInit),
    call(getDataDocumentsShipmentInit),
    call(getPOLotsInit),
    call(fetchShipmentStart),
    call(fetchShipmentMultiPoStart)
  ]);
}
