/* eslint-disable */
import { call, put, takeEvery, select } from 'redux-saga/effects';
import {
  getActivities,
  deleteActivity,
  getDocuments,
  deleteDocument,
  createDocument,
  createShipmentDetails,
  createShipmentList,
  updatePOLot,
  deletePOLot,
  getPurchaseByPO,
  getShipmentDetails,
  getShipmentDetailsMultiLotInit,
  getDocumentShipmentInit,
  geDeleteShipmentInit,
  getAllShipment,
  getAllShipmentMultiPo,
  getPOLots,
  updateShipmentDetails
} from '../services';
import {
  setState,
  fetchShipmentListStart,
  fetchShipmentListMultiPoStart,
  getDocumentsShipmentInit
} from './actions';
import POLinesActionTypes from './types';
import get from 'lodash/get';
import filter from 'lodash/filter';

export default function*() {
  yield takeEvery(POLinesActionTypes.SET_STATE, function*(action) {
    if (!action.payload.type) return;
    switch (action.payload.type) {
      case 'START-FETCH-ACTIVITIES':
        yield put(setState({ activitiesLoading: true }));
        try {
          const response = yield call(getActivities, action.payload.id);
          yield put(setState({ activities: get(response, 'data.data', []) }));
        } catch (error) {
          yield put(setState({ activitiesError: error.message }));
        }
        yield put(setState({ activitiesLoading: false }));
        break;
      case 'START-DELETE-ACTIVITY':
        const { id } = action.payload;
        const activitiesSelect = state => state.purchaseLines.activities;
        const activities = yield select(activitiesSelect);
        yield put(setState({ activityDeleteID: id, activitiesError: null }));
        try {
          yield call(deleteActivity, id);
          yield put(
            setState({
              activities: filter(activities, act => act.id !== id),
              activityDeleteID: null
            })
          );
        } catch (error) {
          yield put(
            setState({
              activityDeleteID: null,
              activitiesError: error.message
            })
          );
        }
        break;
      case 'GET-DOCUMENTS':
        try {
          const response = yield call(getDocuments, action.payload.id);
          yield put(setState({ documents: get(response, 'data.data', []) }));
        } catch (error) {
          yield put(setState({ documents: [], documentsError: error.message }));
        }
        break;
      case 'DELETE_DOCUMENT':
        try {
          yield put(setState({ isDeleting: true }));
          yield call(deleteDocument, action.payload.id);
          const documentsSelect = state => state.purchaseLines.documents;
          const documents = yield select(documentsSelect);
          yield put(
            setState({
              documents: filter(documents, document => document.id !== action.payload.id),
              documentDeleteID: action.payload.id,
              newDocument: null,
              isDeleting: false
            })
          );
        } catch (error) {
          yield put(
            setState({
              documentDeleteID: null,
              activitiesError: error.message,
              newDocument: null,
              isDeleting: false
            })
          );
        }
        break;
      case 'UPLOAD_DOCUMENT_INVALID':
        yield put(
          setState({
            documentsError: 'Error: Maximum file size is 20 MB'
          })
        );
        break;
      case 'UPLOAD_DOCUMENT':
        yield put(
          setState({
            isUploading: true
          })
        );
        const response = yield call(createDocument, action.payload.data);
        const documentsSelect = state => state.purchaseLines.documents;
        const oldaAtivitiesSelect = state => state.purchaseLines.activities;
        const documents = yield select(documentsSelect);
        const oldActivities = yield select(oldaAtivitiesSelect);
        const { newDocument, newActivity } = response.data;
        const poDetailList = (yield call(getPurchaseByPO, action.payload.data.puchaseOrderId)).data;

        yield put(
          setState({
            poDetailList,
            documents: [...documents, newDocument],
            activities: newActivity ? [...oldActivities, newActivity] : oldActivities,
            newDocument: newDocument,
            documentsError: null,
            isUploading: false
          })
        );
        break;
      case 'ADD_SHIPMENT_DETAILS':
        yield put(setState({ isLoadingShipment: false }));
        yield put(setState({ isShipmentCircularProgress: true }));
        yield call(createShipmentDetails, action.payload.data);
        const purchaseOrderDetail = yield call(getPurchaseByPO, action.payload.data.puchaseOrderId);
        const shipmentDetails = yield call(getShipmentDetails, action.payload.data.puchaseOrderId);
        const documentsShipment = yield call(getDocumentShipmentInit);

        const detailsMultiLot = yield call(
          getShipmentDetailsMultiLotInit,
          action.payload.data.puchaseOrderId
        );

        yield put(
          setState({
            poDetailList: purchaseOrderDetail.data,
            shipmentDetails: shipmentDetails.data,
            documentsShipment: documentsShipment,
            detailsMultiLot: detailsMultiLot.data.data,
            isLoadingShipment: true,
            isShipmentCircularProgress: false,
            errorMessage: null
          })
        );
        break;
      case 'DELETE_SHIPMENT_DETAILS':
        yield put(setState({ isLoadingShipment: false }));
        yield put(setState({ isShipmentCircularProgress: true }));
        yield call(geDeleteShipmentInit, action.payload.data);
        const purchaseOrderDetail1 = yield call(
          getPurchaseByPO,
          action.payload.data.puchaseOrderId
        );
        const shipmentDetails1 = yield call(getShipmentDetails, action.payload.data.puchaseOrderId);
        const documentsShipment1 = yield call(getDocumentShipmentInit);

        const detailsMultiLot1 = yield call(
          getShipmentDetailsMultiLotInit,
          action.payload.data.puchaseOrderId
        );

        yield put(
          setState({
            poDetailList: purchaseOrderDetail1.data,
            shipmentDetails: shipmentDetails1.data,
            documentsShipment: documentsShipment1,
            detailsMultiLot: detailsMultiLot1.data.data,
            isLoadingShipment: true,
            isShipmentCircularProgress: false,
            errorMessage: null
          })
        );
        break;
      case 'ADD_SHIPMENT_LIST':
        try {
          yield put(setState({ isLoadingShipment: false }));
          yield put(setState({ isShipmentCircularProgress: true }));
          yield call(createShipmentList, action.payload.data);
          yield put(setState({ isShipmentCircularProgress: false }));
          yield put(fetchShipmentListStart());
          yield put(fetchShipmentListMultiPoStart());
          yield put(getDocumentsShipmentInit());
        } catch (error) {
          yield put(
            setState({
              isShipmentCircularProgress: false,
              isLoadingShipment: true
            })
          );
        }
        break;
      case 'DELETE_SHIPMENT_LIST':
        yield put(setState({ isLoadingShipment: false }));
        yield put(setState({ isShipmentCircularProgress: true }));
        yield call(geDeleteShipmentInit, action.payload.data);
        const shipmentList1 = yield call(getAllShipment);
        const shipmentListMultiPo1 = yield call(getAllShipmentMultiPo);
        const documentsShipment3 = yield call(getDocumentShipmentInit);

        yield put(
          setState({
            shipmentList: shipmentList1.data,
            shipmentMultiPo: shipmentListMultiPo1.data.data,
            documentsShipment: documentsShipment3,
            isLoadingShipment: true,
            isShipmentCircularProgress: false
          })
        );
        break;
      case 'UPDATE_PO_LOT':
        try {
          yield put(
            setState({
              isSavingPoLot: true
            })
          );
          yield call(updatePOLot, action.payload.data);
          const purchaseOrderDetailResponse = yield call(getPurchaseByPO, action.payload.data.poNo);
          const poLotsResponse = yield call(getPOLots, action.payload.data.poNo);
          const activitiesReponse = yield call(getActivities, action.payload.data.poNo);
          yield put(
            setState({
              poDetailList: purchaseOrderDetailResponse.data,
              poLots: poLotsResponse.data,
              activities: get(activitiesReponse, 'data.data', []),
              createLotSuccess: true,
              isSavingPoLot: false
            })
          );
        } catch (error) {
          yield put(
            setState({
              createLotSuccess: false,
              errorMessage: error.message,
              isSavingPoLot: false
            })
          );
        }
        break;
      case 'DELETE_PO_LOT':
        try {
          yield put(setState({ isDeleting: true }));
          const {
            data: { poNo, itemNo, lotNo, uniCompanyName }
          } = action.payload;
          yield call(deletePOLot, action.payload.data);
          const poLotsSelect = state => state.purchaseLines.poLots;
          const poLots = yield select(poLotsSelect);
          const index = poLots.findIndex(
            lot =>
              lot.po_no === poNo &&
              lot.item_no === itemNo &&
              lot.lot_no === lotNo &&
              lot.uni_company_name === uniCompanyName
          );
          if (index !== -1) {
            poLots.splice(index, 1);
          }
          yield put(
            setState({
              poLots: poLots,
              isDeleting: false
            })
          );
        } catch (error) {
          yield put(
            setState({
              error: error.message,
              isDeleting: false
            })
          );
        }
        break;
      case 'REMOVE_ERROR':
        yield put(
          setState({
            errorMessage: null,
            createLotSuccess: false
          })
        );
        break;
      case 'REMOVE_SUCCESS_STATUS':
        yield put(
          setState({
            errorMessage: null,
            createLotSuccess: false
          })
        );

      case 'CLICK_PROGRESS_BAR':
        const { statusCircle, previousPath } = action.payload;
        yield put(
          setState({
            statusCircle,
            previousPath
          })
        );
        break;

      case 'UPDATE_SHIPMENT_DETAILS':
        yield put(setState({ isFetching: true }));

        try {
          const { data } = action.payload;
          yield call(updateShipmentDetails, data);

          const purchaseOrderDetail = yield call(getPurchaseByPO, data.po_no);
          const shipmentDetails = yield call(getShipmentDetails, data.po_no);
          const documentsShipment = yield call(getDocumentShipmentInit);
          const detailsMultiLot = yield call(getShipmentDetailsMultiLotInit, data.po_no);
          const response = yield call(getActivities, data.po_no);

          yield put(
            setState({
              poDetailList: purchaseOrderDetail.data,
              shipmentDetails: shipmentDetails.data,
              documentsShipment: documentsShipment.data.data,
              detailsMultiLot: detailsMultiLot.data.data,
              isFetching: false,
              errorMessage: null,
              activities: get(response, 'data.data', [])
            })
          );
        } catch (error) {
          yield put(setState({ isFetching: false, errorMessage: error.message }));
        }
        break;

      default:
        break;
    }
  });
}
