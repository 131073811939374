import SnackbarActionTypes from './types';

export const toggleSnackbar = () => ({
  type: SnackbarActionTypes.TOGGLE
});

export const openSnackbar = payload => ({
  type: SnackbarActionTypes.OPEN,
  payload
});

export const closeSnackbar = () => ({
  type: SnackbarActionTypes.CLOSE
});
