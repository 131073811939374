import POLinesActionTypes from './types';

// REQUEST API DATA
export const fetchPoDetailStart = po_line_id => ({
  type: POLinesActionTypes.FETCH_PO_DETAILED_START,
  payload: po_line_id
});

// RECEIVED API DATA
export const fetchPoDetailSuccess = purchaseOrderDetail => ({
  type: POLinesActionTypes.FETCH_PO_DETAILED_LINES_SUCCESS,
  payload: purchaseOrderDetail
});

export const fetchPoDetailFailure = error => ({
  type: POLinesActionTypes.FETCH_PO_DETAILED_LINES_FAILURE,
  payload: error
});

// REQUEST API DATA FOR EMAIL THREAD
export const fetchPoEmailStart = po_line_id => ({
  type: POLinesActionTypes.FETCH_PO_EMAIL_THREAD_START,
  payload: po_line_id
});

// RECEIVED API DATA
export const fetchPoEmailSuccess = emailThread => ({
  type: POLinesActionTypes.FETCH_PO_EMAIL_THREAD_SUCCESS,
  payload: emailThread
});

export const fetchImportantMessagesEmailInit = id => ({
  type: POLinesActionTypes.FETCH_IMPORTANT_MESSAGES_EMAIL_THREAD_INIT,
  payload: id
});

export const fetchImportantMessagesEmailSuccess = importantMessages => ({
  type: POLinesActionTypes.FETCH_IMPORTANT_MESSAGES_EMAIL_THREAD_SUCCESS,
  payload: importantMessages
});

export const fetchImportantMessagesEmailSuccessFailure = error => ({
  type: POLinesActionTypes.FETCH_IMPORTANT_MESSAGES_EMAIL_THREAD_FAILURE,
  payload: error
});

// RECEIVED API DATA
export const fetchPoEmailFailure = error => ({
  type: POLinesActionTypes.FETCH_PO_EMAIL_THREAD_FAILURE,
  payload: error
});

export const getEmailById = id => ({
  type: POLinesActionTypes.FETCH_PO_EMAIL_BY_ID_INIT,
  payload: id
});

export const getEmailByIdSuccess = email => ({
  type: POLinesActionTypes.FETCH_PO_EMAIL_BY_ID_SUCCESS,
  payload: email
});

export const getEmailByIdFailure = error => ({
  type: POLinesActionTypes.FETCH_PO_EMAIL_BY_ID_FAILURE,
  payload: error
});

export const setShipmentDetails = shipmentDetails => ({
  type: POLinesActionTypes.FETCH_PO_SHIPMENT_SUCCESS,
  payload: shipmentDetails
});

export const setState = value => ({
  type: POLinesActionTypes.SET_STATE,
  payload: value
});

export const deleteDocument = value => ({
  type: POLinesActionTypes.DELETE_DOCUMENT,
  payload: value
});

export const deleteDocumentSuccess = value => ({
  type: POLinesActionTypes.DELETE_DOCUMENT_SUCCESS,
  payload: value
});

export const deleteDocumentFailure = value => ({
  type: POLinesActionTypes.DELETE_DOCUMENT_FAILURE,
  payload: value
});

export const uploadInvalidFile = value => ({
  type: POLinesActionTypes.UPLOAD_DOCUMENT_INVALID,
  payload: value
});

export const getForwardInit = () => ({
  type: POLinesActionTypes.FETCH_FORWARDER_INIT
});

export const getForwardSuccess = forwarderList => ({
  type: POLinesActionTypes.FETCH_FORWARDER_SUCCESS,
  payload: forwarderList
});

export const getForwardFailure = error => ({
  type: POLinesActionTypes.FETCH_FORWARDER_FAILURE,
  payload: error
});

export const getShipmentDetailsMultiLotInit = po_no => ({
  type: POLinesActionTypes.FETCH_SHIPMENT_DETAILS_MULTI_LOT_INIT,
  payload: po_no
});

export const getShipmentDetailsMultiLotSuccess = detailsMultiLot => ({
  type: POLinesActionTypes.FETCH_SHIPMENT_DETAILS_MULTI_LOT_SUCCESS,
  payload: detailsMultiLot
});

export const getShipmentDetailsMultiLotFailure = error => ({
  type: POLinesActionTypes.FETCH_SHIPMENT_DETAILS_MULTI_LOT_FAILURE,
  payload: error
});

export const getDocumentsShipmentInit = () => ({
  type: POLinesActionTypes.FETCH_DOCUMENTS_SHIPMENT_INIT
  // payload: sh_no
});

export const getDocumentsShipmentSuccess = documentsShipment => ({
  type: POLinesActionTypes.FETCH_DOCUMENTS_SHIPMENT_SUCCESS,
  payload: documentsShipment
});

export const getDocumentsShipmentFailure = error => ({
  type: POLinesActionTypes.FETCH_DOCUMENTS_SHIPMENT_FAILURE,
  payload: error
});

export const getDataChangeShipmentInit = data => ({
  type: POLinesActionTypes.ADD_SHIPMENT_DETAILS_INIT,
  payload: data
});

export const getDataChangeShipmentSuccess = dataChangeShipment => ({
  type: POLinesActionTypes.ADD_SHIPMENT_DETAILS_SUCCESS,
  payload: dataChangeShipment
});

export const getDataChangeShipmentFailure = error => ({
  type: POLinesActionTypes.ADD_SHIPMENT_DETAILS_FAILURE,
  payload: error
});

export const getDeleteShipmentInit = data => ({
  type: POLinesActionTypes.DELETE_SHIPMENT_DETAILS_INIT,
  payload: data
});

export const getDeleteShipmentSuccess = deleteShipment => ({
  type: POLinesActionTypes.DELETE_SHIPMENT_DETAILS_SUCCESS,
  payload: deleteShipment
});

export const getDeleteShipmentFailure = error => ({
  type: POLinesActionTypes.DELETE_SHIPMENT_DETAILS_FAILURE,
  payload: error
});

export const getPOLots = poNo => ({
  type: POLinesActionTypes.FETCH_PO_LOT,
  payload: poNo
});

export const getPOLotsSuccess = data => ({
  type: POLinesActionTypes.FETCH_PO_LOT_SUCCESS,
  payload: data
});

export const getPOLotsFailure = error => ({
  type: POLinesActionTypes.FETCH_PO_LOT_FAILURE,
  payload: error
});

export const fetchShipmentListStart = () => ({
  type: POLinesActionTypes.FETCH_SHIPMENT_LIST_START
});

export const fetchShipmentListSuccess = shipmentList => ({
  type: POLinesActionTypes.FETCH_SHIPMENT_LIST_SUCCESS,
  payload: shipmentList
});

export const fetchShipmentListFailure = error => ({
  type: POLinesActionTypes.FETCH_SHIPMENT_LIST_FAILURE,
  payload: error
});

export const fetchShipmentListMultiPoStart = () => ({
  type: POLinesActionTypes.FETCH_SHIPMENT_LIST_MULTI_PO_START
});

export const fetchShipmentListMultiPoSuccess = shipmentMultiPo => ({
  type: POLinesActionTypes.FETCH_SHIPMENT_LIST_MULTI_PO_SUCCESS,
  payload: shipmentMultiPo
});

export const fetchShipmentListMultiPoFailure = error => ({
  type: POLinesActionTypes.FETCH_SHIPMENT_LIST_MULTI_PO_FAILURE,
  payload: error
});
