const POLinesActionTypes = {
  FETCH_PO_DETAILED_START: 'FETCH_PO_DETAILED_START',
  FETCH_PO_DETAILED_LINES_SUCCESS: 'FETCH_PO_DETAILED_LINES_SUCCESS',
  FETCH_PO_DETAILED_LINES_FAILURE: 'FETCH_PO_DETAILED_LINES_FAILURE',
  FETCH_PO_EMAIL_THREAD_START: 'FETCH_PO_EMAIL_THREAD_START',
  FETCH_PO_EMAIL_THREAD_SUCCESS: 'FETCH_PO_EMAIL_THREAD_SUCCESS',
  FETCH_PO_EMAIL_THREAD_FAILURE: 'FETCH_PO_EMAIL_THREAD_FAILURE',
  FETCH_PO_EMAIL_BY_ID_INIT: 'FETCH_PO_EMAIL_BY_ID_INIT',
  FETCH_PO_EMAIL_BY_ID_SUCCESS: 'FETCH_PO_EMAIL_BY_ID_SUCCESS',
  FETCH_PO_EMAIL_BY_ID_FAILURE: 'FETCH_PO_EMAIL_BY_ID_FAILURE',
  FETCH_PO_SHIPMENT_SUCCESS: 'FETCH_PO_SHIPMENT_SUCCESS',
  FETCH_IMPORTANT_MESSAGES_EMAIL_THREAD_INIT: 'FETCH_IMPORTANT_MESSAGES_EMAIL_THREAD_INIT',
  FETCH_IMPORTANT_MESSAGES_EMAIL_THREAD_SUCCESS: 'FETCH_IMPORTANT_MESSAGES_EMAIL_THREAD_SUCCESS',
  FETCH_IMPORTANT_MESSAGES_EMAIL_THREAD_FAILURE: 'FETCH_IMPORTANT_MESSAGES_EMAIL_THREAD_FAILURE',
  SET_STATE: 'SET_STATE',
  DELETE_DOCUMENT: 'DELETE_DOCUMENT',
  DELETE_DOCUMENT_SUCCESS: 'DELETE_DOCUMENT_SUCCESS',
  DELETE_DOCUMENT_FAILURE: 'DELETE_DOCUMENT_FAILURE',
  UPLOAD_DOCUMENT_INVALID: 'UPLOAD_DOCUMENT_INVALID',
  UPLOAD_DOCUMENT: 'UPLOAD_DOCUMENT',
  FETCH_FORWARDER_SUCCESS: 'FETCH_FORWARDER_SUCCESS',
  FETCH_FORWARDER_INIT: 'FETCH_FORWARDER_INIT',
  FETCH_FORWARDER_FAILURE: 'FETCH_FORWARDER_FAILURE',
  FETCH_SHIPMENT_DETAILS_MULTI_LOT_INIT: 'FETCH_SHIPMENT_DETAILS_MULTI_LOT_INIT',
  FETCH_SHIPMENT_DETAILS_MULTI_LOT_SUCCESS: 'FETCH_SHIPMENT_DETAILS_MULTI_LOT_SUCCESS',
  FETCH_SHIPMENT_DETAILS_MULTI_LOT_FAILURE: 'FETCH_SHIPMENT_DETAILS_MULTI_LOT_FAILURE',
  FETCH_DOCUMENTS_SHIPMENT_INIT: 'FETCH_DOCUMENTS_SHIPMENT_INIT',
  FETCH_DOCUMENTS_SHIPMENT_SUCCESS: 'FETCH_DOCUMENTS_SHIPMENT_SUCCESS',
  FETCH_DOCUMENTS_SHIPMENT_FAILURE: 'FETCH_DOCUMENTS_SHIPMENT_FAILURE',
  ADD_SHIPMENT_DETAILS: 'ADD_SHIPMENT_DETAILS',
  ADD_SHIPMENT_LIST: 'ADD_SHIPMENT_LIST',
  ADD_SHIPMENT_DETAILS_INIT: 'ADD_SHIPMENT_DETAILS_INIT',
  EDIT_SHIPMENT_DETAILS_INIT: 'EDIT_SHIPMENT_DETAILS_INIT',
  ADD_SHIPMENT_DETAILS_SUCCESS: 'ADD_SHIPMENT_DETAILS_SUCCESS',
  ADD_SHIPMENT_DETAILS_FAILURE: 'ADD_SHIPMENT_DETAILS_FAILURE',
  EDIT_SHIPMENT_DETAILS_SUCCESS: 'EDIT_SHIPMENT_DETAILS_SUCCESS',
  EDIT_SHIPMENT_DETAILS_FAILURE: 'EDIT_SHIPMENT_DETAILS_FAILURE',
  DELETE_SHIPMENT_DETAILS_INIT: 'DELETE_SHIPMENT_DETAILS_INIT',
  DELETE_SHIPMENT_DETAILS_SUCCESS: 'DELETE_SHIPMENT_DETAILS_SUCCESS',
  DELETE_SHIPMENT_DETAILS_FAILURE: 'DELETE_SHIPMENT_DETAILS_FAILURE',
  FETCH_PO_LOT: 'FETCH_PO_LOT',
  FETCH_PO_LOT_SUCCESS: 'FETCH_PO_LOT_SUCCESS',
  FETCH_PO_LOT_FAILURE: 'FETCH_PO_LOT_FAILURE',
  DELETE_SHIPMENT_DETAILS: 'DELETE_SHIPMENT_DETAILS',
  REMOVE_ERROR: 'REMOVE_ERROR',
  REMOVE_SUCCESS_STATUS: 'REMOVE_SUCCESS_STATUS',
  DELETE_SHIPMENT_LIST: 'DELETE_SHIPMENT_LIST',
  FETCH_SHIPMENT_LIST_START: 'FETCH_SHIPMENT_LIST_START',
  FETCH_SHIPMENT_LIST_SUCCESS: 'FETCH_SHIPMENT_LIST_SUCCESS',
  FETCH_SHIPMENT_LIST_FAILURE: 'FETCH_SHIPMENT_LIST_FAILURE',
  FETCH_SHIPMENT_LIST_MULTI_PO_START: 'FETCH_SHIPMENT_LIST_MULTI_PO_START',
  FETCH_SHIPMENT_LIST_MULTI_PO_SUCCESS: 'FETCH_SHIPMENT_LIST_MULTI_PO_SUCCESS',
  FETCH_SHIPMENT_LIST_MULTI_PO_FAILURE: 'FETCH_SHIPMENT_LIST_MULTI_PO_FAILURE'
};

export default POLinesActionTypes;
