import { HEADER_PO_LIST } from 'data/header-po';
import PurchaseActionTypes from './types';

const INITIAL_STATE = {
  orderList: null,
  itemFilterList: null,
  isFetching: false,
  errorMessage: undefined,
  header: HEADER_PO_LIST
};

const purchaseReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PurchaseActionTypes.FETCH_PO_LIST_START:
      return {
        ...state,
        isFetching: true
      };
    case PurchaseActionTypes.FETCH_PO_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        orderList: action.payload
      };
    case PurchaseActionTypes.FETCH_PO_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload
      };
    case PurchaseActionTypes.FETCH_ITEM_FILTER_START:
      return {
        ...state
        //isFetching: true
      };
    case PurchaseActionTypes.FETCH_ITEM_FILTER_SUCCESS:
      return {
        ...state,
        //isFetching: false,
        itemFilterList: action.payload
      };
    case PurchaseActionTypes.FETCH_ITEM_FILTER_FAILURE:
      return {
        ...state,
        // isFetching: false,
        errorMessage: action.payload
      };
    default:
      return state;
  }
};

export default purchaseReducer;
