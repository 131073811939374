import axios from 'config/client';

export const getItemFilter = async () => {
  try {
    const itemFilter = await axios.get('item-filter');
    return itemFilter;
  } catch (error) {
    console.error(`Error: ${error.message}`);
    if (error.message === 'Network Error') throw new Error('Something went wrong!');
    throw new Error(error.response.data.message);
  }
};

// GET PURCHASE HEADERS
export const getPurchase = async () => {
  try {
    const purchaseMap = await axios.get('purchase-orders');
    return purchaseMap;
  } catch (error) {
    console.error(`Error: ${error.message}`);
    if (error.message === 'Network Error') throw new Error('Something went wrong!');
    throw new Error(error.response.data.message);
  }
};
