import axios from 'config/client';
import { handleError } from 'utils/auth';

export const login = async payload => {
  try {
    const response = await axios.post('/login', payload);
    return response;
  } catch (error) {
    handleError(error);
  }
};

export const tokenLogin = async data => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_FORESENT_API}/token-login`, {
      ...data,
      app: 'ots'
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
