import ReminderEmailTemplatesActionTypes from './types';

export const fetchReminderEmailTemplates = () => ({
  type: ReminderEmailTemplatesActionTypes.FETCH_REMINDER_EMAIL_TEMPLATES
});

export const fetchReminderEmailTemplatesSuccess = reminderEmailTemplates => ({
  type: ReminderEmailTemplatesActionTypes.FETCH_REMINDER_EMAIL_TEMPLATES_SUCCESS,
  payload: reminderEmailTemplates
});

export const fetchReminderEmailTemplatesFailure = error => ({
  type: ReminderEmailTemplatesActionTypes.FETCH_REMINDER_EMAIL_TEMPLATES_FAILURE,
  payload: error
});

export const fetchReminderEmailTemplateDetail = payload => ({
  type: ReminderEmailTemplatesActionTypes.FETCH_REMINDER_EMAIL_TEMPLATE_DETAIL,
  payload: payload
});

export const fetchReminderEmailTemplateDetailSuccess = reminderEmailTemplate => ({
  type: ReminderEmailTemplatesActionTypes.FETCH_REMINDER_EMAIL_TEMPLATE_DETAIL_SUCCESS,
  payload: reminderEmailTemplate
});

export const fetchReminderEmailTemplateDetailFailure = error => ({
  type: ReminderEmailTemplatesActionTypes.FETCH_REMINDER_EMAIL_TEMPLATE_DETAIL_FAILURE,
  payload: error
});

export const setState = value => ({
  type: ReminderEmailTemplatesActionTypes.SET_STATE,
  payload: value
});
