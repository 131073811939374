import { all, call } from 'redux-saga/effects';

// ALL SAGAS
import { purchaseSagas } from 'modules/purchase-order/redux/sagas';
import { purchaseLineSagas } from 'modules/purchase-order-detail/redux/sagas';
import { loginSagaWatcher } from 'modules/auth/redux/saga';
import { statusErrorsSagas } from 'modules/status-error/redux/sagas';
import { batchlotSagas } from 'modules/batch-lot/redux/sagas';
import { shipmentunderqSagas } from 'modules/shipment-under-q/redux/sagas';
import { reminderEmailTemplatesSagas } from 'modules/reminder-email-template/redux/sagas';
import { watchUserInfo } from 'modules/user-info/redux/sagas';

export default function* rootSaga() {
  yield all([
    call(purchaseSagas),
    call(purchaseLineSagas),
    call(loginSagaWatcher),
    call(statusErrorsSagas),
    call(batchlotSagas),
    call(shipmentunderqSagas),
    call(reminderEmailTemplatesSagas),
    call(watchUserInfo)
  ]);
}
