import PurchaseActionTypes from './types';

export const fetchPurchaseStart = () => ({
  type: PurchaseActionTypes.FETCH_PO_LIST_START
});

export const fetchPurchaseSuccess = purchaseOrderList => ({
  type: PurchaseActionTypes.FETCH_PO_LIST_SUCCESS,
  payload: purchaseOrderList
});

export const fetchPurchaseFailure = error => ({
  type: PurchaseActionTypes.FETCH_PO_LIST_FAILURE,
  payload: error
});

export const fetchItemFilterStart = () => ({
  type: PurchaseActionTypes.FETCH_ITEM_FILTER_START
});

export const fetchItemFilterSuccess = purchaseOrderList => ({
  type: PurchaseActionTypes.FETCH_ITEM_FILTER_SUCCESS,
  payload: purchaseOrderList
});

export const fetchItemFilterFailure = error => ({
  type: PurchaseActionTypes.FETCH_PO_LIST_FAILURE,
  payload: error
});
