import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeSnackbar } from '../redux/actions';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

const SnackbarComponent = ({ children }) => {
  const { open, message, type, anchorOrigin } = useSelector(state => state.snackbar);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeSnackbar());
  };
  return (
    <React.Fragment>
      <Snackbar
        open={open}
        anchorOrigin={anchorOrigin}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={type}>
          {message}
        </Alert>
      </Snackbar>
      {children}
    </React.Fragment>
  );
};

export default SnackbarComponent;
