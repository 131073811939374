export const HEADER_PO_LIST = [
  {
    id: 1,
    label: 'PO Numbers',
    minWidth: 170,
    align: 'left',
    icon: '',
    status: 1
  },
  {
    id: 2,
    label: 'Company Name',
    minWidth: 170,
    align: 'left',
    icon: '',
    status: 1
  },
  {
    id: 3,
    label: 'Item',
    minWidth: 170,
    align: 'left',
    icon: '',
    status: 1
  },
  {
    id: 4,
    label: 'Outstanding Quantity',
    format: value => value.toLocaleString('en-US'),
    minWidth: 100,
    align: 'left',
    icon: '',
    status: 1
  },
  {
    id: 5,
    label: 'Ordered Quantity',
    minWidth: 100,
    format: value => value.toLocaleString('en-US'),
    align: 'left',
    icon: '',
    status: 1
  },
  {
    id: 6,
    label: 'Unit of Measure',
    minWidth: 100,
    align: 'left',
    icon: '',
    status: 1
  },
  {
    id: 7,
    label: 'Planned Receipt Date',
    minWidth: 170,
    align: 'left',
    icon: '',
    status: 1
  }
];
