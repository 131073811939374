import React from 'react';
import ReactDOM from 'react-dom';
import Router from 'router';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from 'store';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../src/utils/themes';
import Snackbar from 'modules/snackbar/screens';
import './index.scss';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <PersistGate loading={null} persistor={persistor}>
        <Provider store={store}>
          <Snackbar>
            <Router />
          </Snackbar>
        </Provider>
      </PersistGate>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById('root')
);
