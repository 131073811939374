import axios from 'config/client';

// GET STATUS ERRORS
export const getStatusErrors = async () => {
  try {
    return await axios.get('status-error');
  } catch (error) {
    console.error(`Error: ${error.message}`);
    if (error.message === 'Network Error') throw new Error('Something went wrong!');
    throw new Error(error.response.data.message);
  }
};

// DELETE STATUS ERROR
export const deleteStatusError = async payload => {
  try {
    const { id, poNo, reasonType } = payload;
    return await axios.delete(`status-error/${id}/po-no/${poNo}/reason/${reasonType}`);
  } catch (error) {
    console.error(`Error: ${error.message}`);
    if (error.message === 'Network Error') throw new Error('Something went wrong!');
    throw new Error(error.response.data.message);
  }
};
