const PurchaseActionTypes = {
  FETCH_PO_LIST_START: 'FETCH_PO_LIST_START',
  FETCH_PO_LIST_SUCCESS: 'FETCH_PO_LIST_SUCCESS',
  FETCH_PO_LIST_FAILURE: 'FETCH_PO_LIST_FAILURE',
  FETCH_ITEM_FILTER_START: 'FETCH_ITEM_FILTER_START',
  FETCH_ITEM_FILTER_SUCCESS: 'FETCH_ITEM_FILTER_SUCCESS',
  FETCH_ITEM_FILTER_FAILURE: 'FETCH_ITEM_FILTER_FAILURE'
};

export default PurchaseActionTypes;
