// REDUX SAGA
import { takeLatest, call, put } from 'redux-saga/effects';
import { login, tokenLogin } from '../services';
import { setState } from './actions';
import AuthTypes from './types';
import Cookie from 'js-cookie';

const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;

function* tokenLoginRequest(action) {
  try {
    const response = yield call(tokenLogin, action.payload);

    if (response && response.data) {
      const { accessToken } = response.data;
      yield localStorage.setItem('access_token', accessToken);
      yield put(
        setState({ ...response.data, isAuth: true, isFetching: false, accessDenied: false })
      );
    } else {
      yield put(
        setState({
          error: 'Incorrect username or password. Please try again.',
          isFetching: false,
          accessDenied: false
        })
      );
    }
  } catch (error) {
    yield Cookie.remove('access_token', { domain: cookieDomain });
    yield put(
      setState({
        isFetching: false,
        isAuth: false,
        cookieToken: null
      })
    );
  }
}

function* loginSaga(action) {
  try {
    const response = yield call(login, action.payload);

    if (response && response.data) {
      const { accessToken } = response.data;
      Cookie.set('access_token', accessToken, { domain: cookieDomain });
      yield localStorage.setItem('access_token', accessToken);
      yield put(
        setState({ ...response.data, isAuth: true, isFetching: false, accessDenied: false })
      );
    } else {
      yield put(
        setState({
          error: 'Incorrect username or password. Please try again.',
          isFetching: false,
          accessDenied: false
        })
      );
    }
  } catch (error) {
    yield put(
      setState({
        error: error.message,
        isFetching: false,
        accessDenied: error.message === '403'
      })
    );
  }
}
function* logoutSaga() {
  try {
    yield Cookie.remove('access_token', { domain: cookieDomain });
  } catch (error) {
    console.log(error);
  }
}

export function* loginSagaWatcher() {
  yield takeLatest(AuthTypes.LOGIN, loginSaga);
  yield takeLatest(AuthTypes.LOGOUT, logoutSaga);
  yield takeLatest(AuthTypes.TOKEN_LOGIN, tokenLoginRequest);
}
