import { takeLatest, put, call } from 'redux-saga/effects';
import UserInfoActionTypes from './types';
import {
  getUserInfoSuccessAction,
  createUserInfoSuccessAction,
  updateUserInfoSucessAction,
  fetchUserInfoFailureAction
} from './actions';

import { getUserInfo, createUserInfo, updateUserInfo } from '../services';

export function* getUserInfoSaga(action) {
  try {
    const response = yield call(getUserInfo, action.payload);
    const { result = [] } = response.data || {};
    yield put(getUserInfoSuccessAction({ result }));
  } catch (error) {
    yield put(fetchUserInfoFailureAction(error.message));
  }
}

function* createUserInfoSaga(action) {
  try {
    const response = yield call(createUserInfo, action.payload);
    const { result } = response.data || {};
    yield put(createUserInfoSuccessAction({ result }));
  } catch (error) {
    yield put(fetchUserInfoFailureAction(error.message));
  }
}

function* updateUserInfoSaga(action) {
  try {
    const response = yield call(updateUserInfo, action.payload);
    const { result } = response.data || {};
    yield put(updateUserInfoSucessAction({ result }));
  } catch (error) {
    yield put(fetchUserInfoFailureAction(error.message));
  }
}

export function* watchUserInfo() {
  yield takeLatest(UserInfoActionTypes.GET_USER_INFO, getUserInfoSaga);
  yield takeLatest(UserInfoActionTypes.CREATE_USER_INFO, createUserInfoSaga);
  yield takeLatest(UserInfoActionTypes.UPDATE_USER_INFO, updateUserInfoSaga);
}
