import SnackbarActionTypes from './types';

const INITIAL_STATE = {
  open: false,
  message: '',
  type: 'info',
  anchorOrigin: { vertical: 'top', horizontal: 'center' }
};

const snackbarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SnackbarActionTypes.TOGGLE:
      return {
        ...state,
        open: !state.open
      };
    case SnackbarActionTypes.OPEN:
      return {
        ...state,
        open: true,
        message: action.payload.message,
        type: action.payload.type || INITIAL_STATE.type,
        anchorOrigin: action.payload.anchorOrigin || INITIAL_STATE.anchorOrigin
      };
    case SnackbarActionTypes.CLOSE:
      return {
        ...state,
        open: false
      };
    default:
      return state;
  }
};

export default snackbarReducer;
