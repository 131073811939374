import axios from 'config/client';

// GET shipmentunderq list
export const getShipmentUnderQList = async () => {
  try {
    return await axios.get('underq-list');
  } catch (error) {
    console.error(`Error: ${error.message}`);
    if (error.message === 'Network Error') throw new Error('Something went wrong!');
    throw new Error(error.response.data.message);
  }
};
