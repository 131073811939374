// REDUX SAGA
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { getPurchase, getItemFilter } from '../services';
import get from 'lodash/get';

import {
  fetchPurchaseSuccess,
  fetchPurchaseFailure,
  fetchItemFilterSuccess,
  fetchItemFilterFailure
} from './actions';
import PurchaseActionTypes from './types';

function* getPurchaseOrderList() {
  try {
    const purchaseOrderList = yield call(getPurchase);
    yield put(fetchPurchaseSuccess(get(purchaseOrderList, 'data', [])));
  } catch (error) {
    yield put(fetchPurchaseFailure, error);
  }
}

export function* fetchPurchaseStart() {
  yield takeLatest(PurchaseActionTypes.FETCH_PO_LIST_START, getPurchaseOrderList);
}

function* getItemFilterList() {
  try {
    const itemFilterList = yield call(getItemFilter);
    yield put(fetchItemFilterSuccess(get(itemFilterList, 'data', [])));
  } catch (error) {
    yield put(fetchItemFilterFailure, error);
  }
}

export function* fetchItemFilterStart() {
  yield takeLatest(PurchaseActionTypes.FETCH_ITEM_FILTER_START, getItemFilterList);
}

export function* purchaseSagas() {
  yield all([call(fetchPurchaseStart), call(fetchItemFilterStart)]);
}
