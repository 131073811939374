import StatusErrorsActionTypes from './types';

export const fetchStatusErrorStart = () => ({
  type: StatusErrorsActionTypes.FETCH_STATUS_ERRORS_START
});

export const fetchStatusErrorSuccess = statusErrorList => ({
  type: StatusErrorsActionTypes.FETCH_STATUS_ERRORS_SUCCESS,
  payload: statusErrorList
});

export const fetchStatusErrorFailure = error => ({
  type: StatusErrorsActionTypes.FETCH_STATUS_ERRORS_FAILURE,
  payload: error
});

export const deleteStatusErrorStart = value => ({
  type: StatusErrorsActionTypes.DELETE_STATUS_ERROR_START,
  payload: value
});

export const deleteStatusErrorSuccess = value => ({
  type: StatusErrorsActionTypes.DELETE_STATUS_ERROR_SUCCESS,
  payload: value
});

export const deleteStatusErrorFailure = error => ({
  type: StatusErrorsActionTypes.DELETE_STATUS_ERROR_FAILURE,
  payload: error
});
