import axios from 'config/client';

export const getUserInfo = async userId => {
  try {
    const response = await axios.get('/user/getInfo', { params: { userId } });
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const createUserInfo = async payload => {
  try {
    return await axios.post('/user/createInfo', { data: payload });
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const updateUserInfo = async payload => {
  try {
    return await axios.post('/user/updateInfo', { data: payload });
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getUserSignedUrl = async params => {
  const { username, name, type, size, body } = params;
  const formData = new FormData();
  formData.append('userId', username);
  formData.append('fileName', name);
  formData.append('fileType', type);
  formData.append('fileSize', size);
  formData.append('body', body);
  try {
    const res = await axios.post('/s3/getSignedURL', formData);

    return res.status === 200 ? res.data : null;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
