// REDUX SAGA
import { takeLatest, call, put, all } from 'redux-saga/effects';
import get from 'lodash/get';

import {
  fetchReminderEmailTemplatesSuccess,
  fetchReminderEmailTemplatesFailure,
  fetchReminderEmailTemplateDetailFailure,
  fetchReminderEmailTemplateDetailSuccess,
  setState
} from './actions';
import ReminderEmailTemplatesActionTypes from './types';
import { getReminderEmailTemplates, getReminderEmailTemplate } from '../services';

function* getRET() {
  try {
    yield put(setState({ isFetching: true }));
    const reminderEmailTemplates = yield call(getReminderEmailTemplates);
    yield put(fetchReminderEmailTemplatesSuccess(get(reminderEmailTemplates, 'data.data', [])));
  } catch (error) {
    yield put(fetchReminderEmailTemplatesFailure, error);
  }
}

function* getReminderEmailTemplateDetail(action) {
  try {
    yield put(setState({ isFetching: true }));
    const reminderEmailTemplate = yield call(getReminderEmailTemplate, action.payload);
    yield put(fetchReminderEmailTemplateDetailSuccess(get(reminderEmailTemplate, 'data.data', [])));
  } catch (error) {
    yield put(fetchReminderEmailTemplateDetailFailure, error);
  }
}

export function* fetchReminderEmailTemplates() {
  yield takeLatest(ReminderEmailTemplatesActionTypes.FETCH_REMINDER_EMAIL_TEMPLATES, getRET);
}

export function* fetchReminderEmailTemplateDetail() {
  yield takeLatest(
    ReminderEmailTemplatesActionTypes.FETCH_REMINDER_EMAIL_TEMPLATE_DETAIL,
    getReminderEmailTemplateDetail
  );
}

export function* reminderEmailTemplatesSagas() {
  yield all([call(fetchReminderEmailTemplates), call(fetchReminderEmailTemplateDetail)]);
}
