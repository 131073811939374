import axios from 'config/client';
import axiosOriginal from 'axios';
import moment from 'moment';

// export const getItemFilter = async () => {
//   try {
//     const itemFilter = await axios.get('item-filter');
//     return itemFilter;
//   } catch (error) {
//     console.error(`Error: ${error.message}`);
//     if (error.message === 'Network Error') throw new Error('Something went wrong!');
//     throw new Error(error.response.data.message);
//   }
// };

// // GET PURCHASE HEADERS
// export const getPurchase = async () => {
//   try {
//     const purchaseMap = await axios.get('purchase-orders');
//     return purchaseMap;
//   } catch (error) {
//     console.error(`Error: ${error.message}`);
//     if (error.message === 'Network Error') throw new Error('Something went wrong!');
//     throw new Error(error.response.data.message);
//   }
// };

export const getPurchaseByPO = async po_id => {
  try {
    const purchaseLineMap = await axios.get(`purchase-order/${po_id}`);
    return purchaseLineMap;
  } catch (error) {
    console.error(`Error: ${error.message}`);
    if (error.message === 'Network Error') {
      throw new Error('Something went wrong!');
    }
    throw new Error(error.response.data.message);
  }
};

export const getPOEmailThread = async po_id => {
  try {
    const purchaseEmailLineMap = await axios.get(`purchase-lines-email/${po_id}`);
    return purchaseEmailLineMap;
  } catch (error) {
    console.error(`Error: ${error.message}`);
    if (error.message === 'Network Error') {
      throw new Error('Something went wrong!');
    }
    throw new Error(error.response.data.message);
  }
};

export const getEmailById = async payload => {
  try {
    return await axios.get(`email/${payload.po}/${payload.sh}`);
  } catch (error) {
    if (error.message === 'Network Error') {
      throw new Error('Something went wrong!');
    }
    throw new Error(error.response.data.error);
  }
};

export const getDocuments = async id => {
  try {
    return await axios.get(`documents/${id}`);
  } catch (error) {
    if (error.message === 'Network Error') {
      throw new Error('Something went wrong!');
    }
    throw new Error(error.response.data.message);
  }
};

export const getShipmentDetails = async id => {
  try {
    return await axios.get(`shipment-details/${id}`);
  } catch (error) {
    if (error.message === 'Network Error') {
      throw new Error('Something went wrong!');
    }
    throw new Error(error.response.data.message);
  }
};

export const getActivities = async id => {
  try {
    return await axios.get(`activity/${id}`);
  } catch (error) {
    if (error.message === 'Network Error') {
      throw new Error('Something went wrong!');
    }
    throw new Error(error.response.data.message);
  }
};

export const deleteActivity = async id => {
  try {
    return await axios.delete(`activity/${id}`);
  } catch (error) {
    if (error.message === 'Network Error') {
      throw new Error('Something went wrong!');
    }
    throw new Error(error.response.data.message);
  }
};

export const getImportantMessagesEmailThread = async payload => {
  try {
    const purchaseEmailLineMap = await axios.get(`important-messages-email/${payload}`);
    return purchaseEmailLineMap;
  } catch (error) {
    console.error(`Error: ${error.message}`);
    if (error.message === 'Network Error') {
      throw new Error('Something went wrong!');
    }
    throw new Error(error.response.data.message);
  }
};

export const deleteDocument = async id => {
  try {
    return await axios.delete(`documents/${id}`);
  } catch (error) {
    if (error.message === 'Network Error') {
      throw new Error('Something went wrong!');
    }
    throw new Error(error.response.data.message);
  }
};

export const getForwardList = async () => {
  try {
    const forwarderList = await axios.get('freight-forwarder');
    return forwarderList;
  } catch (error) {
    console.error(`Error: ${error.message}`);
    if (error.message === 'Network Error') throw new Error('Something went wrong!');
    throw new Error(error.response.data.message);
  }
};

export const getShipmentDetailsMultiLotInit = async po_no => {
  try {
    const detailsMultiLot = await axios.get(`shipment-multi-lot/${po_no}`);
    return detailsMultiLot;
  } catch (error) {
    console.error(`Error: ${error.message}`);
    if (error.message === 'Network Error') throw new Error('Something went wrong!');
    throw new Error(error.response.data.message);
  }
};

export const getDocumentShipmentInit = async () => {
  try {
    let promises = [];
    for (let index = 1; index <= 10; index++) {
      promises.push(axios.get(`shipment-documents?chunk=${index}`));
    }

    let documentsShipment = [];
    const results = await Promise.all(promises);
    for (const result of results) {
      if (!result || !result.data || !result.data.data) continue;

      documentsShipment = documentsShipment.concat(result.data.data);
    }

    return documentsShipment;
  } catch (error) {
    console.error(`Error: ${error.message}`);
    if (error.message === 'Network Error') throw new Error('Something went wrong!');
    throw new Error(error.response.data.message);
  }
};

export const createDocument = async payload => {
  try {
    const { file, username, isSentPO, presigendUrlData, puchaseOrderId } = payload;
    const {
      data: { key, url }
    } = presigendUrlData;
    const options = {
      headers: {
        'Content-Type': file.type
      }
    };
    await axiosOriginal.put(url, file, options);
    const params = {
      key: key,
      updated_by: username,
      location: url.split('?').shift(),
      fileType: file.type,
      fileName: file.name,
      isSentPO: isSentPO,
      fileSize: file.size
    };
    const response = await axios.post(`/documents/${puchaseOrderId}`, params);
    return response;
  } catch (error) {
    if (error.message === 'Network Error') {
      throw new Error('Something went wrong!');
    }
    throw new Error(error.response.data.message);
  }
};

export const createShipmentList = async payload => {
  try {
    const { mode, selectbox, username, file, po_no } = payload;

    const formData = new FormData();
    formData.append('file', file);
    const selectboxJSON = JSON.stringify(selectbox);

    // Include additional information in the formData
    formData.append('userId', username);
    if (file) {
      const fileType = file.type;
      const fileSize = file.size;
      const fileName = file.name;
      formData.append('fileName', fileName);
      formData.append('fileType', fileType);
      formData.append('fileSize', fileSize);
    }
    formData.append('updated_by', username);
    formData.append('po_no', po_no);
    formData.append('selectboxJSON', selectboxJSON);
    formData.append('multiPoJSON', JSON.stringify(selectbox.box?.multiPo));

    if (mode === 'add') {
      return await axios.post('add-shipment-list', formData);
    } else {
      return await axios.post('edit-shipment-list', formData);
    }
  } catch (error) {
    if (error.message === 'Network Error') {
      throw new Error('Something went wrong!');
    }
    throw new Error(error.response.data.message);
  }
};

export const createShipmentDetails = async payload => {
  try {
    const {
      mode,
      file,
      selectbox,
      username,
      uni_company_name,
      remark,
      presigendUrlData,
      puchaseOrderId
    } = payload;

    if (presigendUrlData !== '') {
      const {
        data: { key, url }
      } = presigendUrlData;
      const options = {
        headers: {
          'Content-Type': file.type
        }
      };

      await axiosOriginal.put(url, file, options);

      const params = {
        key: key,
        updated_by: username,
        location: url.split('?').shift(),
        fileType: file.type,
        fileName: file.name,
        size: file.size,
        selectbox: selectbox,
        uni_company_name: uni_company_name,
        remark: remark
      };
      if (mode === 'add') {
        return await axios.post(`/add-shipment-details/${puchaseOrderId}`, params);
      } else {
        return await axios.post(`/edit-shipment-details/${puchaseOrderId}`, params);
      }
    } else {
      const params = {
        key: '',
        updated_by: username,
        location: '',
        fileType: '',
        fileName: '',
        size: '',
        selectbox: selectbox,
        uni_company_name: uni_company_name,
        remark: remark
      };
      if (mode === 'add') {
        return await axios.post(`/add-shipment-details/${puchaseOrderId}`, params);
      } else {
        return await axios.post(`/edit-shipment-details/${puchaseOrderId}`, params);
      }
    }
  } catch (error) {
    if (error.message === 'Network Error') {
      throw new Error('Something went wrong!');
    }
    console.log(error);
  }
};

export const geDeleteShipmentInit = async payload => {
  try {
    const { sh_no, username, puchaseOrderId } = payload;

    const params = {
      sh_no: sh_no,
      username: username
    };

    const deleteShipment = await axios.post(`/delete-shipment/${puchaseOrderId}`, params);
    return deleteShipment;
  } catch (error) {
    console.error(`Error: ${error.message}`);
    if (error.message === 'Network Error') {
      throw new Error('Something went wrong!');
    }
    throw new Error(error.response.data.message);
  }
};

export const updatePOLot = async payload => {
  try {
    const { poNo, itemNo, poLots, isCreateBA, isCreateCOA, uniCompanyName } = payload;
    poLots.map(lot => {
      lot.manufacturing_date = moment(lot.manufacturing_date).format('YYYY-MM-DD');
      lot.expiry_date = moment(lot.expiry_date).format('YYYY-MM-DD');
      delete lot.changeExpiryDate;
      delete lot.changeManufacturingDate;
      return lot;
    });
    const response = await axios.put(`purchase-order/${poNo}/item-no/${itemNo}`, {
      poLots: poLots,
      isCreateBA: isCreateBA,
      isCreateCOA: isCreateCOA,
      uniCompanyName: uniCompanyName
    });
    return response.data;
  } catch (error) {
    if (error.message === 'Network Error' || error.message) {
      throw new Error('Something went wrong!');
    }
    throw new Error(error.response.data.message);
  }
};

export const getPOLots = async poNo => {
  try {
    const response = await axios.get(`purchase-order/${poNo}/po-lots`);
    return response.data;
  } catch (error) {
    if (error.message === 'Network Error') {
      throw new Error('Something went wrong!');
    }
    throw new Error(error.response.data.message);
  }
};

export const deletePOLot = async payload => {
  try {
    const { poNo, itemNo, lotNo, uniCompanyName } = payload;
    const response = await axios.delete('lot-no', {
      data: {
        poNo: poNo,
        itemNo: itemNo,
        lotNo: lotNo,
        uniCompanyName: uniCompanyName
      }
    });
    return response.data;
  } catch (error) {
    if (error.message === 'Network Error') {
      throw new Error('Something went wrong!');
    }
    throw new Error(error.response.data.message);
  }
};

export const getAllShipment = async () => {
  try {
    return await axios.get('shipment-list');
  } catch (error) {
    if (error.message === 'Network Error') {
      throw new Error('Something went wrong!');
    }
    throw new Error(error.response.data.message);
  }
};

export const getAllShipmentMultiPo = async () => {
  try {
    return await axios.get('shipment-list-multi-po');
  } catch (error) {
    if (error.message === 'Network Error') {
      throw new Error('Something went wrong!');
    }
    throw new Error(error.response.data.message);
  }
};

export const updateShipmentDetails = async payload => {
  try {
    return await axios.post('/edit-shipment-details', payload);
  } catch (error) {
    if (error.message === 'Network Error') {
      throw new Error('Something went wrong!');
    }
    throw new Error(error.response.data.message);
  }
};
