import { combineReducers } from 'redux';

// Redux Persist
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Reducer
import purchaseReducer from 'modules/purchase-order/redux/reducer';
import purchaseDetailReducer from 'modules/purchase-order-detail/redux/reducer';
import authReducer from 'modules/auth/redux/reducer';
import statusErrorReducer from 'modules/status-error/redux/reducer';
import batchlotReducer from 'modules/batch-lot/redux/reducer';
import shipmentUnderQReducer from 'modules/shipment-under-q/redux/reducer';
import reminderEmailTemplateReducer from 'modules/reminder-email-template/redux/reducer';
import snackbarReducer from 'modules/snackbar/redux/reducer';
import userInfoReducer from 'modules/user-info/redux/reducer';

const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ["purchase", "auth"]
  whitelist: ['auth']
};

const rootReducer = combineReducers({
  purchase: purchaseReducer,
  purchaseLines: purchaseDetailReducer,
  auth: authReducer,
  statusError: statusErrorReducer,
  batchlot: batchlotReducer,
  shipmentunderq: shipmentUnderQReducer,
  reminderEmailTemplate: reminderEmailTemplateReducer,
  snackbar: snackbarReducer,
  userInfo: userInfoReducer
});

export default persistReducer(persistConfig, rootReducer);
