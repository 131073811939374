import AuthTypes from './types';

export const login = payload => ({
  type: AuthTypes.LOGIN,
  payload
});

export const setState = payload => ({
  type: AuthTypes.SET_STATE,
  payload
});

export const logout = payload => ({
  type: AuthTypes.LOGOUT,
  payload
});

export const accessDenied = payload => ({
  type: AuthTypes.ACCESS_DENIED,
  payload
});

export const tokenLogin = payload => ({
  type: AuthTypes.TOKEN_LOGIN,
  payload
});

export default { login, setState, logout, accessDenied, tokenLogin };
