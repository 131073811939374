import UserInfoActionTypes from './types';

const INITIAL_STATE = {
  error: null,
  isFetching: false,
  data: {},
  isCompleted: false,
  isUpdateCompleted: false
};

const userInfoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserInfoActionTypes.GET_USER_INFO:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case UserInfoActionTypes.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload[0],
        error: null
      };
    case UserInfoActionTypes.CREATE_USER_INFO:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case UserInfoActionTypes.CREATE_USER_INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        error: null
      };
    case UserInfoActionTypes.UPDATE_USER_INFO:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case UserInfoActionTypes.UPDATE_USER_INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.result,
        error: null
      };
    case UserInfoActionTypes.FETCH_USER_INFO_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default userInfoReducer;
