import loadable from '@loadable/component';

const Logout = loadable(() => import('modules/auth/screens/logout'));
const AccessDenied = loadable(() => import('components/AccessDenied'));
const LoginPage = loadable(() => import('modules/auth/containers'));
// const VerifyToken = loadable(() => import('modules/auth/screens/VerifyToken'));
const PurchaseOrder = loadable(() => import('modules/purchase-order/containers'));
const EmailPreview = loadable(() => import('modules/email-preview/screens'));
const PurchaseOrderDetail = loadable(() => import('modules/purchase-order-detail/containers'));
const ShipmentPage = loadable(() => import('modules/shipment/containers'));
const BatchLotPage = loadable(() => import('modules/batch-lot/containers'));
const ShipmentUnderQPage = loadable(() => import('modules/shipment-under-q/containers'));
const StatusErrorList = loadable(() => import('modules/status-error/containers'));
const ReminderEmailTemplates = loadable(() => import('modules/reminder-email-template/screens'));
const ReminderEmailTemplateDetail = loadable(() =>
  import('modules/reminder-email-template/screens/ReminderDetail')
);
const PageNotFound = loadable(() => import('components/PageNotFound'));

const routes = [
  // { path: '/verify-token', Component: VerifyToken, exact: true, noLayout: true },
  {
    path: '/login',
    Component: LoginPage,
    noLayout: true,
    exact: true
  },
  {
    path: '/logout',
    Component: Logout,
    noLayout: true,
    exact: true
  },
  {
    path: '/access-denied',
    Component: AccessDenied,
    exact: true
  },
  { path: '/purchase-order', Component: PurchaseOrder, exact: true },
  { path: '/purchase-order/:puchaseOrderId', Component: PurchaseOrderDetail, exact: true },
  {
    path: '/purchase-order/:puchaseOrderId/id/:idMail/email/:sh?',
    Component: EmailPreview,
    exact: true
  },
  { path: '/shipmentlist', Component: ShipmentPage, exact: true },
  { path: '/batchlotlist', Component: BatchLotPage, exact: true },
  { path: '/shipmentunderqlist', Component: ShipmentUnderQPage, exact: true },
  { path: '/status-error', Component: StatusErrorList, exact: true },
  { path: '/reminder-email-templates', Component: ReminderEmailTemplates, exact: true },
  {
    path: '/reminder-email-templates/unicity-company-name/:uniCompanyName/event/:eventType',
    Component: ReminderEmailTemplateDetail,
    exact: true
  },
  {
    Component: PageNotFound,
    noLayout: true
  }
];

export default routes;
