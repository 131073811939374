const ReminderEmailTemplatesActionTypes = {
  FETCH_REMINDER_EMAIL_TEMPLATES: 'FETCH_REMINDER_EMAIL_TEMPLATES',
  FETCH_REMINDER_EMAIL_TEMPLATES_SUCCESS: 'FETCH_REMINDER_EMAIL_TEMPLATES_SUCCESS',
  FETCH_REMINDER_EMAIL_TEMPLATES_FAILURE: 'FETCH_REMINDER_EMAIL_TEMPLATES_FAILURE',
  FETCH_REMINDER_EMAIL_TEMPLATE_DETAIL: 'FETCH_REMINDER_EMAIL_TEMPLATE_DETAIL',
  FETCH_REMINDER_EMAIL_TEMPLATE_DETAIL_SUCCESS: 'FETCH_REMINDER_EMAIL_TEMPLATE_DETAIL_SUCCESS',
  FETCH_REMINDER_EMAIL_TEMPLATE_DETAIL_FAILURE: 'FETCH_REMINDER_EMAIL_TEMPLATE_DETAIL_FAILURE',
  SET_STATE: 'SET_STATE'
};

export default ReminderEmailTemplatesActionTypes;
