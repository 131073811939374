const SnackbarActionTypes = {
  GET_USER_INFO: 'GET_USER_INFO',
  CREATE_USER_INFO: 'CREATE_USER_INFO',
  UPDATE_USER_INFO: 'UPDATE_USER_INFO',
  GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
  CREATE_USER_INFO_SUCCESS: 'CREATE_USER_INFO_SUCCESS',
  UPDATE_USER_INFO_SUCCESS: 'UPDATE_USER_INFO_SUCCESS',
  FETCH_USER_INFO_FAILURE: 'FETCH_USER_INFO_FAILURE'
};

export default SnackbarActionTypes;
