import ReminderEmailTemplatesActionTypes from './types';

const INITIAL_STATE = {
  reminderEmailTemplates: [],
  isFetching: true,
  errorMessage: undefined,
  reminderEmailTemplate: undefined
};

const reminderEmailTemplateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ReminderEmailTemplatesActionTypes.FETCH_REMINDER_EMAIL_TEMPLATES:
      return {
        ...state,
        isFetching: true
      };
    case ReminderEmailTemplatesActionTypes.FETCH_REMINDER_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        reminderEmailTemplates: action.payload
      };
    case ReminderEmailTemplatesActionTypes.FETCH_REMINDER_EMAIL_TEMPLATES_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload
      };
    case ReminderEmailTemplatesActionTypes.FETCH_REMINDER_EMAIL_TEMPLATE_DETAIL:
      return {
        ...state,
        isFetching: true
      };
    case ReminderEmailTemplatesActionTypes.FETCH_REMINDER_EMAIL_TEMPLATE_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        reminderEmailTemplate: action.payload.shift()
      };
    case ReminderEmailTemplatesActionTypes.FETCH_REMINDER_EMAIL_TEMPLATE_DETAIL_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    default:
      return state;
  }
};

export default reminderEmailTemplateReducer;
