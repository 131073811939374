// REDUX SAGA
import { takeLatest, takeEvery, call, put, select, all } from 'redux-saga/effects';
import { deleteStatusError } from 'modules/status-error/services';
import { getShipmentUnderQList } from '../services';
import get from 'lodash/get';
import filter from 'lodash/filter';

import {
  fetchStatusErrorSuccess,
  fetchStatusErrorFailure,
  deleteStatusErrorSuccess,
  deleteStatusErrorFailure
} from './actions';
import StatusErrorsActionTypes from './types';

function* fetchStatusErrorsStart() {
  try {
    const statusErrorList = yield call(getShipmentUnderQList);
    yield put(fetchStatusErrorSuccess(get(statusErrorList, 'data', [])));
  } catch (error) {
    yield put(fetchStatusErrorFailure(error.message));
  }
}

export function* watchFetchStatusErrorsStart() {
  yield takeLatest(StatusErrorsActionTypes.FETCH_STATUS_ERRORS_START, fetchStatusErrorsStart);
}

function* deleteStatusErrorStart(action) {
  try {
    yield call(deleteStatusError, action.payload);

    const statusErrorSlect = state => state.statusError.statusErrorList;
    const statusError = yield select(statusErrorSlect);
    yield put(
      deleteStatusErrorSuccess(filter(statusError, status => status.id !== action.payload.id))
    );
  } catch (error) {
    yield put(deleteStatusErrorFailure(error.message));
  }
}

export function* watchDeleteStatusErrorStart() {
  yield takeEvery(StatusErrorsActionTypes.DELETE_STATUS_ERROR_START, deleteStatusErrorStart);
}

export function* shipmentunderqSagas() {
  yield all([call(watchFetchStatusErrorsStart), call(watchDeleteStatusErrorStart)]);
}
